import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

function NextArrow(props: any) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="rounded-full z-20 bg-white flex items-center justify-center p-4">
        <img src="/assets/icons.arrow-up.svg" alt="" className="" />
      </div>
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="rounded-full z-20 bg-white flex items-center justify-center p-4">
        <img src="/assets/icons.arrow-up.svg" alt="" className="" />
      </div>
    </div>
  );
}

const SliderHome = () => {
  const initialCars: Car[] = [];
  const [cars, setCars] = useState(initialCars);
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(0);
  let sliderRef = useRef(null);

  interface Car {
    id: number;
    name: string;
    year: number;
    perDayPrice: number;
    images: { url: string }[];
  }





  const fetchData = async () => {
    try {
      const apiUrl =
        "https://enqlha-api.applore.in/api/dashboard/car/getAllCarsByPagination?limit=3&page=1&search=";
    
        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log("data",data)
        setCars(data.data);
        console.error("Token is missing.");

      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
  
    fetchData();
  }, []);


  const settings = {
    centerMode: true,
    centerPadding: "22%",
    slidesToShow: 1,
    infinite: true,

    speed: 500,
    arrows: false,
    afterChange: (current: any) => setActiveSlide(current),
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  console.log(activeSlide);

  const arr: any = [
    {
      id: 1,
      img: "/assets/images/car1.jpeg",
    },
    {
      id: 2,
      img: "/assets/images/car2.jpeg",
    },
    {
      id: 3,
      img: "/assets/images/car3.jpeg",
    },
  ];

  return (
    <div className="w-full flex flex-col items-center gap-16 mt-[520px] md:mt-40 ">
      <div className="w-full flex flex-col items-center gap-4">
        <h1 className="text-[#7F778F]  text-lg md:text-2xl font-bold px-3 md:px-0">
          {t(`sliderHome.title`)}
        </h1>
        <p className=" text-xs md:text-sm font-medium text-[#333333] px-6">
          {t(`sliderHome.description`)}
        </p>
      </div>
      <div className="w-full relative ">
        <div
          onClick={() => {
            if (sliderRef.current) {
              (sliderRef.current as any).slickPrev();
            }
          }}
          className=" cursor-pointer  absolute  rounded-full z-20 left-[20%] md:left-[20.5%]  top-[22%] md:top-[20%] bg-white flex items-center justify-center p-2 md:p-4"
        >
          <img src="/assets/icons/arrow-up.svg" alt="" className="" />
        </div>
        <div className="w-full md:min-h-[800px] relative">
          <Slider ref={sliderRef} {...settings}>
            {cars.map((car, index) => (
              <div
                className="cursor-pointer rounded  relative p-2"
                key={car.id}
              >
                <img
                  src={car.images[0].url}
                  alt=""
                  className="rounded-t w-full h-full max-h-[134px] md:max-w-[1000px] md:max-h-[400px] "
                />
                {activeSlide === index && (
                  <div className=" w-full rounded-b-sm bg-black p-4 flex justify-between items-center">
                    <div className="flex flex-col gap-2">
                      <h6 className="text-white text-base md:text-xl font-medium">
                        {car.name}
                      </h6>
                      <p className="text-white text-xs md:text-sm">
                        Model year: {car.year}
                      </p>
                    </div>
                    <h6 className="text-white text-base md:text-xl font-medium">
                      {car.perDayPrice}
                    </h6>
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
        <div
          onClick={() => {
            if (sliderRef.current) {
              (sliderRef.current as any).slickNext();
            }
          }}
          className=" cursor-pointer  absolute  rounded-full rotate-180 z-20 right-[20.5%] top-[22%] md:top-[20%] bg-white flex items-center justify-center p-2 md:p-4"
        >
          <img src="/assets/icons/arrow-up.svg" alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default SliderHome;
