export const locations: any = [
  "Riyadh South",
  "Riyadh West",
  "Riyadh East",
  "Riyadh North",
  "Riyadh City Center",
  "Dammam South",
  "Dammam West",
  "Dammam East",
  "Dammam North",
  "Dammam City Center",
];
